<template>
	<div>
		<template>
      <v-data-table
        :headers="tables.exerciseCategories.headers"
        :items="exerciseCategories"
        :search="tables.exerciseCategories.search"
      >
	      <template v-slot:top>
          <h1>Mozgásterületek</h1>
					<v-row>
						<v-col cols="12" sm="11">
			        <v-text-field
			          v-model="tables.exerciseCategories.search"
			          label="Keresés"
			          prepend-inner-icon="fa-search"
			          class="mx-4"
			        />
						</v-col>
						<v-col cols="12" sm="1">
							<v-tooltip top>
								Hozzáadás
								<template v-slot:activator="{on, attrs}">
									<v-btn fab color="success" v-on="on" v-bind="attrs" @click="dialogs.createExerciseCategory.show=true"><v-icon>fa-plus</v-icon></v-btn>
								</template>
							</v-tooltip>
						</v-col>
					</v-row>
	      </template>

        <template v-slot:item.actions="{item}">
          <v-tooltip top>
            Törlés
            <template v-slot:activator="{on, attrs}">
              <v-btn icon color="red accent-4" v-on="on" v-bind="attrs" @click="dialogs.deleteExerciseCategory.exerciseCategoryId= item.id, dialogs.deleteExerciseCategory.show=true"><v-icon>fa-trash</v-icon></v-btn>
            </template>
          </v-tooltip>
					<v-tooltip top>
            Szerkesztés
            <template v-slot:activator="{on, attrs}">
              <v-btn icon color="primary" v-on="on" v-bind="attrs" @click="dialogs.changeExerciseCategory.exerciseCategory= {...item}, dialogs.changeExerciseCategory.show=true"><v-icon>fa-edit</v-icon></v-btn>
            </template>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-data-table
        :headers="tables.measurementUnits.headers"
        :items="measurementUnits"
      >
	      <template v-slot:top>
          <h1>Mértékegységek</h1>
          <v-tooltip top>
            Hozzáadás
            <template v-slot:activator="{on, attrs}">
              <v-btn fab color="success" v-on="on" v-bind="attrs" @click="dialogs.createMeasurementUnit.show=true"><v-icon>fa-plus</v-icon></v-btn>
            </template>
          </v-tooltip>
	      </template>

        <template v-slot:item.actions="{item}">
          <v-tooltip top>
            Törlés
            <template v-slot:activator="{on, attrs}">
              <v-btn icon color="red accent-4" v-on="on" v-bind="attrs" @click="dialogs.deleteMeasurementUnit.measurementUnitId= item.id, dialogs.deleteMeasurementUnit.show=true"><v-icon>fa-trash</v-icon></v-btn>
            </template>
          </v-tooltip>
					<v-tooltip top>
            Szerkesztés
            <template v-slot:activator="{on, attrs}">
              <v-btn icon color="primary" v-on="on" v-bind="attrs" @click="dialogs.changeMeasurementUnit.measurementUnit= {...item}, dialogs.changeMeasurementUnit.show=true"><v-icon>fa-edit</v-icon></v-btn>
            </template>
          </v-tooltip>
        </template>
      </v-data-table>
		  <v-row justify="center">
		    <v-dialog
		      v-model="dialogs.createExerciseCategory.show"
		      persistent
		      max-width="600px"
		    >
		      <v-card>
		        <v-card-title>
		          <span class="headline">Új mozgásterület</span>
		        </v-card-title>
		        <v-card-text>
		          <v-container>
								<v-text-field
									prepend-inner-icon="fa-layer-group"
									label="Mozgásterület"
									v-model="dialogs.createExerciseCategory.exerciseCategory.name"
									rounded
									outlined
								/>
								<v-textarea
									prepend-inner-icon="fa-info-circle"
									label="Leírás"
									v-model="dialogs.createExerciseCategory.exerciseCategory.description"
									rounded
									outlined
								/>
		          </v-container>
		        </v-card-text>
		        <v-card-actions>
		          <v-spacer></v-spacer>
		          <v-btn
		            color="blue darken-1"
		            text
		            @click="dialogs.createExerciseCategory.show = false"
		          >
		            Mégsem
		          </v-btn>
		          <v-btn
		            color="blue darken-1"
		            text
		            @click="createExerciseCategory()"
								v-if="dialogs.createExerciseCategory.exerciseCategory.name&&exerciseCategories.filter(f => f.name.toUpperCase()==dialogs.createExerciseCategory.exerciseCategory.name.toUpperCase()).length==0"
		          >
		            Hozzáadás
		          </v-btn>
		        </v-card-actions>
		      </v-card>
  	    </v-dialog>
				<v-dialog
					v-model="dialogs.deleteExerciseCategory.show"
					persistent
					max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Mozgásterület törlése</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								Biztosan törölni szeretné a mozgásterületet?
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.deleteExerciseCategory.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="deleteExerciseCategory(dialogs.deleteExerciseCategory.exerciseCategoryId)"
							>
								Törlés
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog
					v-model="dialogs.changeExerciseCategory.show"
					persistent
					max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Mozgásterület szerkesztése</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								<v-text-field
									v-model="dialogs.changeExerciseCategory.exerciseCategory.name"
									label="Mozgásterület"
									prepend-inner-icon="fa-layer-group"
									rounded
									outlined
								/>
								<v-textarea
									v-model="dialogs.changeExerciseCategory.exerciseCategory.description"
									label="Leírás"
									prepend-inner-icon="fa-info-circle"
									rounded
									outlined
								/>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.changeExerciseCategory.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="changeExerciseCategory()"
							>
								Módosítás
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>


        <v-dialog
          v-model="dialogs.createMeasurementUnit.show"
          persistent
          max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Új mértékegység</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-text-field
                  prepend-inner-icon="fa-info"
                  label="Mértékegység"
                  v-model="dialogs.createMeasurementUnit.unit"
									rounded
									outlined
                />
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialogs.createMeasurementUnit.show = false"
              >
                Mégsem
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="createMeasurementUnit()"
                v-if="dialogs.createMeasurementUnit.unit&&dialogs.createMeasurementUnit.unit.length<=10&&measurementUnits.filter(mu => mu.unit.toUpperCase()==dialogs.createMeasurementUnit.unit.toUpperCase()).length==0"
              >
                Hozzáadás
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogs.deleteMeasurementUnit.show"
          persistent
          max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Mértékegység törlése</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                Biztosan törölni szeretné a mértékegységet? A törléssel a törlendő mértékegységgel rögzített gyakorlatok is törlődni fognak az edzéstervbekből.
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialogs.deleteMeasurementUnit.show = false"
              >
                Mégsem
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="deleteMeasurementUnit(dialogs.deleteMeasurementUnit.measurementUnitId)"
              >
                Törlés
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
				<v-dialog
					v-model="dialogs.changeMeasurementUnit.show"
					persistent
					max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Mértékegység szerkesztése</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								<v-text-field
									v-model="dialogs.changeMeasurementUnit.measurementUnit.unit"
									label="Mértékegység"
									prepend-inner-icon="fa-info"
									rounded
									outlined
								/>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.changeMeasurementUnit.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								v-if="measurementUnits.filter(mu => mu.unit==dialogs.changeMeasurementUnit.measurementUnit.unit).length==0&&dialogs.changeMeasurementUnit.measurementUnit.unit&&dialogs.changeMeasurementUnit.measurementUnit.unit.length<=10"
								@click="changeMeasurementUnit()"
							>
								Módosítás
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
	    </v-row>
	  </template>
	</div>
</template>

<script>
	import router from '../router';
	export default {
		data: function(){
			return {
        exerciseCategories: [],
        measurementUnits: [],
        tables: {
          exerciseCategories: {
            search: '',
            headers: [
              {
                text: "Mozgásterület",
                align: "center",
                sortable: true,
                filterable: true,
                value: "name"
              },
              {
                text: "Leírás",
                align: "center",
                sortable: false,
                filterable: true,
                value: "description"
              },
							{
								text: "Műveletek",
								align: "center",
								sortable: false,
								filterable: false,
								value: "actions"
							}
            ]
          },
          measurementUnits: {
            headers: [
              {
                text: "Mértékegység",
                align: "center",
                sortable: true,
                filterable: true,
                value: "unit"
              },
							{
								text: "Műveletek",
								align: "center",
								sortable: false,
								filterable: false,
								value: "actions"
							}
            ]
          }
        },
        dialogs: {
          createExerciseCategory: {
            show: false,
            exerciseCategory: {
              name: '',
              description: ''
            }
          },
					deleteExerciseCategory: {
            show: false,
            exerciseCategoryId: -1
          },
					changeExerciseCategory: {
						show: false,
						exerciseCategory: {id: -1, name: '', description: ''}
					},

          createMeasurementUnit: {
            show: false,
            unit: ''
          },
          deleteMeasurementUnit: {
            show: false,
            measurementUnitId: -1
          },
					changeMeasurementUnit: {
						show: false,
						measurementUnit: {unit: '', id: -1}
					}
        }
      }
		},
		computed: {

		},
		watch: {

		},
		methods: {
			createExerciseCategory: function() {
				this.axios({url: "exercise/category", method: "POST", data: this.dialogs.createExerciseCategory.exerciseCategory}).then((response) => {
					if(response.data.success) {
						this.exerciseCategories.push({...this.dialogs.createExerciseCategory.exerciseCategory, id: response.data.data});
						this.dialogs.createExerciseCategory.show=false;
            this.dialogs.createExerciseCategory.exerciseCategory = { name: '', description: '' }
						this.$store.commit('setSnack','A hozzáadás sikeresen megtörtént.')
					}
				})
			},
      deleteExerciseCategory: function(exerciseCategoryId) {
        this.axios({url: "exercise/category/"+exerciseCategoryId, method: "DELETE"}).then(response => {
          if(response.data.success) {
            this.exerciseCategories.splice(this.exerciseCategories.findIndex(eC => eC.id==exerciseCategoryId),1)
            this.dialogs.deleteExerciseCategory.show=false;
            this.$store.commit('setSnack','A törlés sikeresen megtörtént.')
          }
        })
      },
			changeExerciseCategory: function() {
				this.axios({url: "exercise/category/", method: "PUT", data: this.dialogs.changeExerciseCategory.exerciseCategory}).then(response => {
					if(response.data.success){
						var eC = this.exerciseCategories.find(eC => eC.id==this.dialogs.changeExerciseCategory.exerciseCategory.id)
						eC.name= this.dialogs.changeExerciseCategory.exerciseCategory.name
						eC.description=this.dialogs.changeExerciseCategory.exerciseCategory.description
						this.dialogs.changeExerciseCategory.show=false
						this.$store.commit('setSnack', 'A módosítás sikeresen megtörtént')
					}
				})
			},

      createMeasurementUnit: function() {
        this.axios({url: "exercise/measurementunit", method: "POST", data: {unit: this.dialogs.createMeasurementUnit.unit}}).then((response) => {
          if(response.data.success) {
            this.measurementUnits.push({unit: this.dialogs.createMeasurementUnit.unit, id: response.data.data});
            this.dialogs.createMeasurementUnit.show=false;
            this.dialogs.createMeasurementUnit.unit = ''
            this.$store.commit('setSnack','A hozzáadás sikeresen megtörtént.')
          }
        })
      },
      deleteMeasurementUnit: function(measurementUnitId) {
        this.axios({url: "exercise/measurementunit/"+measurementUnitId, method: "DELETE"}).then(response => {
          if(response.data.success) {
            this.measurementUnits.splice(this.measurementUnits.findIndex(mU => mU.id==measurementUnitId),1)
            this.dialogs.deleteMeasurementUnit.show=false;
            this.$store.commit('setSnack','A törlés sikeresen megtörtént.')
          }
        })
      },
			changeMeasurementUnit: function() {
				this.axios({url: "exercise/measurementunit/", method: "PUT", data: this.dialogs.changeMeasurementUnit.measurementUnit}).then(response => {
					if(response.data.success){
						var mU =this.measurementUnits.find(mU => mU.id==this.dialogs.changeMeasurementUnit.measurementUnit.id)
						mU.unit=this.dialogs.changeMeasurementUnit.measurementUnit.unit
						this.dialogs.changeMeasurementUnit.show=false
						this.$store.commit('setSnack', 'A módosítás sikeresen megtörtént')
					}
				})
			}
		},
		mounted(){
			this.axios({url: "exercise/settings", method: "GET"}).then((response) => {
        if(response.data.success) {
          this.measurementUnits = response.data.data.measurementUnits
					this.exerciseCategories=response.data.data.exerciseCategories
        }
			})
		}
	}
</script>
